































































































































import { computed, defineComponent, PropType, ref } from '@vue/composition-api';
import { useTalkRoom, TalkRoomProperty } from '@/admin/talkRoom';
import { useNotification } from '@/composition/notification';
import { useConfirm } from '@/composition/confirm';
import FcDate from '@/components/FcDate.vue';
import FcImage from '@/components/FcImage.vue';
import FcStaticImage from '@/components/FcStaticImage.vue';
import FcAuthority from '@/components/FcAuthority.vue';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';

export default defineComponent({
  name: 'TalkRoom',
  components: {
    FcDate,
    FcImage,
    FcStaticImage,
    FcAuthority,
    FcRoleLoading,
    FcRoleDeny,
  },
  props: {
    talkRoomId: {
      type: String,
      require: false,
      default: '',
    },
    copyTalkRoom: {
      type: Object as PropType<TalkRoomProperty | null>,
      default: null,
    },
  },
  setup({ talkRoomId, copyTalkRoom }, context) {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('talkrooms'));

    const { talkRoom } = useTalkRoom(talkRoomId);
    if (copyTalkRoom) {
      talkRoom.copyTalkRoom(copyTalkRoom);
    }
    const notification = useNotification();
    const { confirmDialog } = useConfirm();
    const isSaving = ref(false);

    const save = async () => {
      if (!(await confirmDialog('本当に保存しますか？'))) return;
      isSaving.value = true;
      try {
        if (talkRoomId) {
          await talkRoom.saveTalkRoom(talkRoomId);
          notification.notify('変更しました。');
        } else {
          await talkRoom.createTalkRoom();
          notification.notify('作成しました。');
          context.root.$router.push('/talkrooms');
        }
      } catch (error) {
        notification.error(error);
      }
      isSaving.value = false;
    };

    return {
      pageTitle: 'トークルーム',
      myRoleSettings,
      talkRoom,
      save,
      isSaving,
    };
  },
});
